<template>
  <div>
    <div class="search">
      <el-input v-model="product_title" clearable>
        <template slot="prepend">搜索宝贝</template>
        <el-button slot="append" icon="el-icon-search" @click="getProductList"></el-button>
      </el-input>
    </div>
    <div class="product_list">
      <el-card shadow="hover" class="product_card" v-for="(product_value, product) in product_list" :key="product">
        <div @click="getProductDetail(product_value)">
          <el-row>
            <el-col :span="16">
              <el-image placeholder="头像加载中" :src="product_value.product_cover_img" class="product_img"></el-image>
            </el-col>
            <el-col :span="8">
              <div class="desc_and_price">
                <div class="product_desc">{{ product_value.product_title }}</div>
                <div class="product_price"><em style="font-size: 13px">¥ </em>{{ product_value.product_price }}</div>
              </div>
            </el-col>
          </el-row>
        </div>
      </el-card>
    </div>
    <!--商品详情弹框-->
    <div>
      <el-dialog destroy-on-close center class="product_dialog" title="商品详情" :visible.sync="dialogProductInfoVisible" width="60%">
        <el-form :model="formData">
          <div>
            <el-row>
              <el-col :span="2">
                <el-image :src="formData.publish_user_img" class="user_head_img"></el-image>
              </el-col>
              <el-col :span="14">
                <div class="publish_user">
                  <span>{{ formData.publish_user_name }}</span>
                  <span>来自 {{ formData.university }}</span>
                </div>
              </el-col>
            </el-row>
            <el-row>
              <div class="price_and_count">
                <div class="detail_product_price">
                  <em style="font-size: 12px">¥ </em>
                  {{ formData.product_price }}
                </div>
                <div>库存量:{{ formData.product_stock }}</div>
              </div>
            </el-row>
            <el-row>
              <div>
                <span>{{ formData.product_title }}</span>
              </div>
            </el-row>
            <el-divider></el-divider>
            <el-row>
              <div style="margin-bottom: 10px">
                <span>{{ formData.product_desc }}</span>
              </div>
            </el-row>
            <el-row>
              <div v-for="(image_url, image) in formData.product_detail_image" :key="image">
                <el-image :src="image_url" placeholder="图片加载中"></el-image>
              </div>
            </el-row>
          </div>
        </el-form>
        <div slot="footer">
          <el-button type="danger" @click="dialogConfirmBuy = true">我想要</el-button>
        </div>
      </el-dialog>
    </div>
    <!--确认购买弹框-->
    <div>
      <el-dialog title="提示" :visible.sync="dialogConfirmBuy" width="25%" center>
        <div class="confirm_dialog">
          <span class="confirm_title">请确认购买数量</span>
          <el-input-number size="small" v-model="buy_count" controls-position="right" :min="1" :max="formData.product_stock"></el-input-number>
        </div>
        <div class="confirm_dialog">
          <span class="confirm_title">请确认收货地址</span>
          <el-select v-model="formData.buyer_address" placeholder="收货地址" style="width: 130px" @click.native="selectAddress">
            <el-option v-for="item in addressList" :key="item.value" :label="item.label" :value="item.value"> </el-option>
          </el-select>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button @click="dialogConfirmBuy = false">取 消</el-button>
          <el-button
            type="primary"
            @click="
              dialogConfirmBuy = false
              createOrder()
            "
            >确 定</el-button
          >
        </span>
      </el-dialog>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TradingHall',
  data() {
    return {
      buy_count: 1,
      addressList: [
        {
          key: '江苏南京浦口亚泰山语湖',
          value: '江苏南京浦口亚泰山语湖'
        }
      ],
      formData: {
        publish_user_img: '',
        publish_user_name: '',
        university: '',
        product_price: '',
        product_title: '',
        product_desc: '',
        product_detail_image: [],
        product_stock: 1,
        publish_user_id: undefined,
        product_id: undefined,
        buyer_address: ''
      },
      dialogConfirmBuy: false,
      dialogProductInfoVisible: false,
      product_title: '',
      product_list: []
    }
  },
  created() {
    this.getProductList()
  },
  methods: {
    selectAddress() {
      this.$api.address.getAddressList().then(res => {
        console.log(res.data.data)
        this.addressList = []
        const address = res.data.data
        for (const a in address) {
          this.addressList.push({
            value: address[a]['detail_address'],
            key: address[a]['id']
          })
        }
      })
    },
    createOrder() {
      this.$api.order.createOrder({ product_id: this.formData.product_id, count: this.buy_count, buyer_address: this.formData.buyer_address }).then(res => {
        if (res.data.code === 200) {
          this.$message.success({
            message: res.data.msg,
            center: true,
            duration: 2000
          })
          this.$router.push('/order_buy')
        } else {
          this.$message.error({
            message: res.data.msg,
            center: true,
            duration: 5000
          })
        }
      })
    },
    getProductDetail(product_value) {
      this.formData = {}
      this.dialogProductInfoVisible = true
      this.$api.product.getProductDetail({ product_id: product_value.product_id }).then(res => {
        if (res.data.code === 200) {
          this.formData = res.data.data
        } else {
          this.$message.error({
            message: res.data.msg,
            center: true,
            duration: 5000
          })
          this.loading = false
        }
      })
    },
    getProductList() {
      this.$api.product.tradingHallGetProductList({ product_title: this.product_title }).then(res => {
        this.product_list = res.data.data
      })
    }
  }
}
</script>

<style scoped>
.search {
  width: 40%;
  margin: 0 auto;
  display: flex;
  justify-content: center;
}
.product_card {
  height: 200px;
  width: 300px;
  margin: 15px;
}
.product_img {
  height: 150px;
  width: 150px;
}
.product_list {
  display: flex;
  flex-wrap: wrap;
}
.desc_and_price {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 150px;
}
.product_desc {
  font-size: 13px;
}
.product_price {
  font-size: 22px;
  color: red;
}
.publish_user {
  display: flex;
  flex-direction: column;
  color: #8c939d;
}
.user_head_img {
  width: 40px;
  height: 40px;
}
.detail_product_price {
  color: red;
  font-size: 18px;
  margin-top: 20px;
}
.product_dialog {
  height: 80%;
}
.price_and_count {
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;
}
.confirm_dialog {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
}
.confirm_title {
  margin-right: 10px;
}
</style>
